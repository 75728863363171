footer{
    background-color: black;
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    img{
        width: 10rem;
        max-height: 4rem;
    }
    h2{
        color: white;
        font-size: 1.6rem;
    }
    a{
        color: white;
        text-decoration: none;
        font-size: 1.1rem;
    }
    h4{
        color: white;
    }
}