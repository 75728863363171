.images{
    padding-top: 2rem;
    .images_container{
        width: 100%;
        margin: 0 auto;
        display: flex;
        overflow: hidden;
        height: auto;
        position: relative;
        // border-radius: 10px;

       
        img{
            width: 100%;
            height: 40rem;
            object-fit: cover;
            object-position: center;
            flex-shrink: 0;
            flex-grow: 0;
            transition: 0.5s;
            // border-radius: 10px;
        }
        .dots{
            position: absolute;
            right: 0;
            bottom: 1rem;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            button{
                font-size: 1.2rem;
                padding: 0.2rem 0.6rem;
                border-radius: 50%;
                border: 1px white solid;
                outline: none;
            }
            
        }
        .btns{
            width: 100%;
            margin: 0.5rem auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 1rem;
            position: absolute;
            // top: 0;
            top: 50%;
            // bottom: 0;
            z-index: 2;
            padding: 0 0.5rem;
            button{
                width: max-content;
                font-size: 1.5rem;
                padding: 0.5rem 1rem;
                border-radius: 50%;
                border: none;
                outline: none;
                background-color: rgba(0, 0, 0, 0.538);
                color: white;
                cursor: pointer;
                transition: 0.5s;
                &:active{
                    transition: 0.5s;
                    transform: scale(0.9);
                    color: red;
                    background-color: rgba(255, 255, 255, 0.634);
                }
            }
        }
    }
   

}