.popup{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 3;
   .phone{
    background-color: green;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    transition: 0.5s;
    &:hover{
        transition: 0.5s;
        transform: scale(0.9);
    }
    box-icon{
        fill: white;
        width: 2.5rem;
        height: 2.5rem;
    }
   }

   .email{
    background-color: orange;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    transition: 0.5s;
    &:hover{
        transition: 0.5s;
        transform: scale(0.9);
    }

    box-icon{
        fill: white;
        width: 2.5rem;
        height: 2.5rem;
    }
   }
   @media only screen and (max-width:500px) {
        .phone, .email{
            width: 3rem;
            height: 3rem;
            &:hover{
                transform: scale(1);
            }
            box-icon{
                width: 2rem;
                height: 2rem;
            }
        }
   }
}