header{
    // background:linear-gradient(to top, rgba(0, 0, 0, 0.467),rgba(0, 0, 0, 0.755)), url(../baner.jpg) no-repeat;
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(4,35,43,1) 35%, rgba(0,0,0,1) 100%);
    background-size: cover;
    height: 40rem;
    display: flex;
    justify-content: center;
    align-items: center;
   
    .header-title{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        span{
            display: flex;
            align-items: last baseline;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.92);
            width:min(100% - 1rem,30rem);
            padding: 0.4rem 0;
            border-radius: 20px;
            img{
                width: 5rem;
                object-fit: contain;
            }
            .logo1{
                color: orange;
                font-size: 4rem;
                font-family:'Times New Roman', Times, serif;
            }
            .logo2{
                color: black;
                font-size: 3rem;
                font-family:'Times New Roman', Times, serif;
            }
        }
        img{
            width:min(100% - 1rem,30rem);
            max-height: 12rem;
            object-fit: cover;
        }
        h1{
            color: white;
            font-size: clamp(1.8rem,4vw,2.5rem);
        }
        p{
            color: white;
            font-size: clamp(1.2rem,4vw,1.4rem);
            width: min(100% - 1rem,30rem);
            text-align: center;
            font-weight: bold;
        }
        .header-btns{
            width:min(100% - 1rem,30rem);
            display: flex;
            justify-content: center;
            gap: 0.5rem;
            align-items: center;
            a{
                width: 40%;
                padding: 0.5rem 0;
                outline: none;
                cursor: pointer;
                color: black;
                background-color: white;
                border-radius: 10px;
                border: none;
                text-decoration: none;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: 0.5s;
                &:active{
                    transition: 0.5s;
                    background-color: orange;
                    color: white;
                }
            }
        }
    }
}