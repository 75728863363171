*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    width: 0.5rem;
}
::-webkit-scrollbar-thumb{
    background-color: black;
    border-radius: 20px;
}
