.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
    .para{
        width: min(100%,90rem);
        text-align: center;
        font-size: 1.3rem;
    }
    h2{
        font-size: 1.7rem;
        margin: 2rem 0;
        text-align: center;
    }
    ul{
        width: min(100%,88rem);
        font-size: clamp(1.1rem, 4vw, 1.3rem);
        list-style: inside decimal;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0.5rem;

    }
    .single{
        // background-color: black;
        width: min(100%,70rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        border: 1px gray solid;
        padding: 1rem;
        border-radius: 20px;
        box-shadow: 3px 3px 4px gray;
        img{
            width: 40rem;
           max-height: 20rem;
           object-fit: cover;
           transition: 0.5s;
           &:hover{
            transition: 0.5s;
            filter: grayscale(1);
           }
        }
        .text-container{
            padding: 1rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
            h1{
                margin-bottom: 0.5rem;
                font-size: 1.6rem;
                text-align: center;
            }
            p{
                color: black;
                font-size: clamp(1.1rem, 4vw, 1.3rem);
                text-align: center;
            }
        }
        
        &:nth-child(odd){
            flex-direction: row-reverse;
        }
        @media only screen and (max-width:900px) {
            flex-direction: column;
            &:nth-child(odd){
                flex-direction: column;
            }
            img{
                width: 100%;
                max-height: 40rem;
            }
        }
    }
}